import { useEffect, useState } from 'react';

export const isSSR = typeof window === 'undefined';
export const isBrowser = typeof window !== 'undefined';

export function capitalizeText(text) {
  return `${text.substr(0, 1).toUpperCase()}${text.substr(1)}`;
}

export const hasError = (errors, inputName) =>
  Boolean(errors && errors[inputName]);

export const getClassError = (errors, inputName) =>
  errors && errors[inputName] ? 'error' : '';

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function onlyNumberKey(event) {
  const theEvent = event || window.event;
  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
    key = event.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  var regex = /[0-9]|\.|-|\+|\(|\)/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function getCodesRegistered(content) {
  const results = [];
  const reg = /#[\S]+#/gm;
  let check;
  while ((check = reg.exec(content)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (check.index === reg.lastIndex) {
      reg.lastIndex++;
    }
    check.forEach((match) => {
      if (!results.includes(match)) {
        results.push(match);
      }
    });
  }
  return results.map((item) => item.replace(/#/g, ''));
}

export function prepareNotificationMessage(content, currenciesAndStocks) {
  const codes = getCodesRegistered(content);
  codes.forEach((code) => {
    const itemsFound = currenciesAndStocks.find(
      (item) => item.code == code
    );
    if (!itemsFound) {
      return;
    }
    const striped = code.replace('/', '\\/');
    const reg = RegExp(`#${striped}#`, 'gm');
    const val = parseFloat(itemsFound.sell);
    content = content.replace(reg, val);
  });
  return content;
}